import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResolutionService {

  serverurl = environment.api_base_url;

  constructor(private http: HttpClient) { }

  getBank() {
    return this.http.get(this.serverurl + 'api/bank/list');
  }

  getCategory() {
    return this.http.get(this.serverurl + 'api/category/list')
  }

  getFlowChart(a) {
    return this.http.get(this.serverurl + 'api/category/showFlowchart',
      {
        params: {
          Category: a
        }
      })
  }


  getServiceProviders() {
    return this.http.get(this.serverurl + 'api/sp/list')
  }

  getEmbassyCountryList() {
    return this.http.get(this.serverurl + 'api/embassy/getCountryList')
  }

  getEmbassyDetails(a) {
    return this.http.get(this.serverurl + 'api/embassy/getEmbassyDetails',
      {
        params: {
          Country: a
        }
      })
  }

  // getEmbassy(a) {
  //   return this.http.get(this.serverurl + 'api/embassy/list',
  //   {
  //   params: {
  //     Country: a
  //   }
  //   })
  // }

  getRtoDetails(a) {
    console.log(a);
    return this.http.get(this.serverurl + 'api/rto/rtoZoneDetails', {
      params: {
        RTOZone: a
      }
    })
  }

  getScriptDetails(a) {
    return this.http.get(this.serverurl + 'api/script/getScriptDetails', {
      params: {
        Category: a
      }
    })
  }

  createTicket(postData: any) {
    return this.http.post(this.serverurl + 'api/ticket/saveTicket', postData)
  }

  getMailerList() {
    return this.http.get(this.serverurl + 'api/mailers/list')
  }

  getSocialMediaList() {
    return this.http.get(this.serverurl + 'api/socialmedias/list')
  }

  getCustomerUsingMobileDetails(a) {
    return this.http.get(this.serverurl + 'api/order/getCustomerUsingOrderDetails', {
      params: {
        Mobile: a
      }
    })
  }

  getCustomerUsingEmailDetails(a) {
    return this.http.get(this.serverurl + 'api/order/getCustomerUsingOrderDetails', {
      params: {
        Email: a
      }
    })
  }

  getCustomerMobileDetails(a) {
    return this.http.get(this.serverurl + 'api/customer/getCustomerUsingDetails', {
      params: {
        Mobile: a
      }
    })
  }

  getCustomerEmailAndMobileDetails(a) {
    return this.http.get(this.serverurl + 'api/customer/getCustomerUsingEmailAndMobile', {
      params: a
    })
  }

  getCustomerEmailDetails(a) {
    return this.http.get(this.serverurl + 'api/customer/getCustomerUsingDetails', {
      params: {
        Email: a
      }
    })
  }

  getCustomerProductDetails(customerId){
    return this.http.get(this.serverurl + 'api/customer/getCustomerProductDetails', {
      params: {
        customerId: customerId
      }
    })
  }

  getCreditReportDetails(customerId,panId){
    return this.http.get(this.serverurl + 'api/customer/getCustomerCreditReport', {
      params: {
        customerId: customerId,
        panId : panId
      }
    })
  }


  // updatecustomerDetails(postData){
  //   return this.http.post(this.serverurl + 'api/customer/create', postData)
  // }

  getBanksDetailUsingMobile(a) {
    return this.http.get(this.serverurl + 'api/bank/customer/getBankCustomerUsingDetails', {
      params: {
        Mobile: a
      }
    })
  }

  getBanksDetailUsingEmail(a) {
    return this.http.get(this.serverurl + 'api/bank/customer/getBankCustomerUsingDetails', {
      params: {
        Email: a
      }
    })
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }


  private handleError(error: Response) {
    console.error(error);
    return Observable.throw(error.json() || 'Server Error');
  }
}
