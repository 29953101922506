import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  serverurl = environment.api_base_url;

  constructor(private http: HttpClient) { }

  verifyVuchrCode(obj):Observable<any>{
    return this.http.get(this.serverurl + 'api/voucher/Voucher_amount', {
      params: {
        pId: obj.pId,
        promoCode: obj.promoCode,
        countryCode:obj.countryCode
      }
    });
  }

  searchEmailMobile(obj): Observable<any> {
    return this.http.get(this.serverurl + 'api/users/BulkUserData', {
      params: {
        mobile: obj.mobile,
        username: obj.username
      }
    });
  }

  load_ProductList(a): Observable<any> {
    return this.http.get(this.serverurl + 'api/product/products_list_crm', {
      params: {
        limit: a
      }
    });
  }

  load_ProductID(a, b, c, d): Observable<any> {
    return this.http.get(this.serverurl + 'api/product/products_Id_crm', {
      params: {
        filter: a,
        select: b,
        limit: c,
        page: d
      }
    });
  }

  getProductList(a, b, c): Observable<any> {
    return this.http.get(this.serverurl + 'api/product/productList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    });
  }

  getSearchProductList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/product/productList', {
      params: {
        search: a,
        limit: b,
        pageNo: c,
        sort: d
      }
    })
  }

  getOrderDetails(order_id) {
    return this.http.get(this.serverurl + 'api/order/orderDetails', { params: { OrderId: order_id } });
  }

  getOrderDetailsByCustomerId(customer_id) {
    return this.http.get(this.serverurl + 'api/order/orderDetailsByCustomer', { params: { customerId: customer_id } });
  }
  getCustomerInfoCRM(customer_id){
    return this.http.get(this.serverurl + 'api/customer/CustomerDetailsById', { params: { customerId: customer_id } });
  }
  getProductTypeList() {
    return this.http.get(this.serverurl + 'api/product/productTypeList');
  }

  getProductTypeListWithIds() {
    return this.http.get(this.serverurl + 'api/product/productTypeListWithIds');
  }

  getProductNameList() {
    return this.http.get(this.serverurl + 'api/product/productNameList');
  }

  getProductFeaturesList(pId) {
    return this.http.get(this.serverurl + 'api/product/getProductFeatures', { params: { productId: pId } });
  }

  getCityList(info) {
    return this.http.get(this.serverurl + 'api/master/state/cityList', { params: { State: info } });
  }

  getCustomerdetail(a) {
    return this.http.get(this.serverurl + 'api/customer/getCustomerDetail', { params: { _id: a } })
  }

  getCustomerdetailById(a) {
    return this.http.get(this.serverurl + 'api/customer/getCustomerDetailById', { params: { CustomerId: a } })
  }

  getWalletDetails() {
    return this.http.get(this.serverurl + 'api/walletpartners/list');
  }

  createCustomerDetail(postData) {
    return this.http.post(this.serverurl + 'api/customer/create', postData);
  }

  calculatePrice(plan) {
    console.log(plan, 'plan')
    return this.http.post(this.serverurl + 'api/order/calculatePrice', plan);
  }

  createOrder(postData: any) {
    return this.http.post(this.serverurl + 'api/order/create', postData);
  }

  payUPayment(a) {
    return this.http.get(this.serverurl + 'api/payumoney/paywithpayumoney', { params: a })
  }

  paytmPayment(info) {
    console.log(info, 'service');
    return this.http.get(this.serverurl + 'api/paytm/paywithpaytm', { params: info })
  }
}
