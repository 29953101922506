import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./services/guard.service";
import { LayoutsAuthComponent } from './pages/layouts/auth/auth';
import { VerifyOtpComponent } from "./verify-otp/verify-otp.component";
import { LoginComponent } from "./login/login.component";
import { ResetCustomerPasswordComponent } from "./pages/reset-customer-password/reset-customer-password.component";

const routes: Routes = [
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'otp-auth', component: VerifyOtpComponent },
  {
    path: '', component: LayoutsAuthComponent, canActivate: [AuthGuard],
    children: [
      { path: "CRM", loadChildren: "./CRM/crm.module#CRMModule", canActivate: [AuthGuard] },
      { path: "FAQ", loadChildren: "./FAQ/faq.module#FAQModule", canActivate: [AuthGuard] },
      { path: "Import_Export", loadChildren: "./Import-Export/import-export.module#ImportExportModule", canActivate: [AuthGuard] },
      { path: "Definitions", loadChildren: "./Definitions/definitions.module#DefinitionsModule", canActivate: [AuthGuard] },
      { path: "User", loadChildren: "./User/user.module#UserModule", canActivate: [AuthGuard] },
      { path: "home", loadChildren: "./pages/home/home.module#HomeModule", canActivate: [AuthGuard] },
      { path: 'resetPassword', component: ResetCustomerPasswordComponent }
    ]
  },
  { path: "**", component: LoginComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
      enableTracing: true,
      // useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

