import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/Users/user.service';

export interface CRMLogin {
  key: string,
  username: string,
  password: string
}

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {

  currentUser: any;
  otp: number;
  userInfo: any;
  private key: string;
  disableOtpBtn: boolean;
  username: any;
  pasword: any;
  reqObj: CRMLogin;

  constructor(private _userServ: UserService, private router: Router, private toastr: ToastrService) {
    this.reqObj = this.router.getCurrentNavigation().extras.state as CRMLogin;

    this.key = this.reqObj.key;
    this.username = this.reqObj.username;
    this.pasword = this.reqObj.password
  }

  ngOnInit() {
    if (!this.key) {
      this.router.navigate(['/login']);
    }
  }

  verifyInfo() {
    let data = {
      otp: this.otp,
      key: this.key
    }
    this._userServ.verifyOTP(data).subscribe(res => {
      this.userInfo = res;
      if (this.userInfo['data'] != null) {
        localStorage.setItem('currentUser', JSON.stringify(this.userInfo.data));
        localStorage.setItem('AgentName', this.userInfo['data'].username);
        localStorage.setItem('AgentId', this.userInfo['data'].id);
        localStorage.setItem('CallCenterName', this.userInfo['data'].CallCenterName);
        if (this.userInfo.data.Role == 'SalesAgent' || this.userInfo.data.Role == 'ResolutionAgent'){
          localStorage.setItem('AgentCountries', this.userInfo['data'].Country);
          localStorage.setItem('CurrentCountry', this.userInfo['data'].Country[0]);
        }

        this.toastr.success(this.userInfo['message']);

        this.currentUser = this.userInfo.data;
        this.disableOtpBtn = false;
        if (this.currentUser.UserType == 'SuperAdmin') {
          this.router.navigate(['/CRM/preSalePanel']);
        } else if (this.currentUser.UserType == 'Admin') {
          this.router.navigate(['/CRM/preSalePanel']);
        } else if (this.currentUser.UserType == 'Agent') {
          if (this.currentUser.Permissions.Resolution) {
            this.router.navigate(['/CRM/Resolution/resolution_Panel']);
          } else {
            this.router.navigate(['/CRM/preSalePanel']);
          }
        } else {
          this.toastr.error('Something Went Wrong!');
        }
      } else {
        this.toastr.error(this.userInfo['output'].payload.message);
      }
    })
  }

  resendOtp() {
    let navInfo = {
      username: this.username,
      password: this.pasword
    }
    this._userServ.getLoginData(navInfo).subscribe(res => {
      this.userInfo = res;
      if (this.userInfo['data'] != null || this.userInfo['data'].key != '' || this.userInfo['data'].key != undefined || this.userInfo['data'].key != null) {
        this.key = this.userInfo['data'].key;
        this.toastr.success(this.userInfo['message']);
      } else {
        this.toastr.error(this.userInfo['output'].payload.message);
      }
    });
  }
}
