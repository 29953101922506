import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocalStorageModule } from 'angular-2-local-storage';
import { AgGridModule } from 'ag-grid-angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

//Installed Modules
import { ToastrModule } from 'ngx-toastr';
import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";
import { FileSaverModule } from 'ngx-filesaver';
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module
import { NgxPrintModule } from 'ngx-print';
import { PaginationModule } from 'ngx-bootstrap';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
// import { FileSelectDirective } from 'ng2-file-upload';

import { MaterialModule } from '../app/services/material-module';
import { MatFormFieldModule } from '@angular/material';

// Headers
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';

// Services
import { AuthGuard } from './services/guard.service';
import { FileUploadService } from './services/fileUpload/file-upload.service';
import { TicketService } from './services/Tickets/ticket.service';
import { UserService } from './services/Users/user.service';

// Components
import { SharedModule } from './shared.module';
import { LayoutsAuthComponent } from './pages/layouts/auth/auth';
import { HeaderComponent } from './widget/header/header.component';
import { FooterComponent } from './widget/footer/footer.component';
import { SideMenuComponent } from './widget/side-menu/side-menu.component';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { LoginComponent } from './login/login.component';
import { ResetCustomerPasswordComponent } from './pages/reset-customer-password/reset-customer-password.component';
import {DatePipe} from "@angular/common";

@NgModule({
  declarations: [
    AppComponent,
    LayoutsAuthComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    SideMenuComponent,
    VerifyOtpComponent,
    ResetCustomerPasswordComponent,
  ],

  imports: [
    BrowserModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    RouterModule,
    FileSaverModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    NgxPrintModule,
    PaginationModule.forRoot(),
    AgGridModule.withComponents([]),
    NgMultiSelectDropDownModule.forRoot(),
    LocalStorageModule.forRoot({
      storageType: 'localStorage'
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      maxOpened: 0,
      autoDismiss: false
    }),
    SharedModule,
    MatFormFieldModule,
    Ng4LoadingSpinnerModule.forRoot()
  ],

  exports: [
    NgxPaginationModule,
    FormsModule,
    HttpClientModule,
    LocalStorageModule,
  ],

  providers: [UserService, AuthGuard, NgxNavigationWithDataComponent, TicketService, FileUploadService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

