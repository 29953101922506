import { Component, OnInit } from '@angular/core';
import { DataDefinitionService } from '../../services/definition/data-definition.service';
import { SalesService } from '../../services/Sales/sales.service';
import { ToastrService } from 'ngx-toastr';
import { ResolutionService } from '../../services/Resolution/resolution.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-customer-password',
  templateUrl: './reset-customer-password.component.html',
  styleUrls: ['./reset-customer-password.component.css']
})
export class ResetCustomerPasswordComponent implements OnInit {

  currentUser;

  fetchedUserDetails: any = {
    Address: '',
    FirstName: '',
    LastName: '',
    City: '',
    State: '',
    Mobile: '',
    PinCode: '',
    EmaiL: '',
    DOB: '',
    PlanExpDate: '',
    ProductType: '',
    ProductName: '',
    CustomerId: '',
    AgentId: '',
    AgentName: '',
    BankName: '',
    SecondaryEmail: ''
  };

  states: any;

  cities: any;

  constructor(private router: Router, private resolution: ResolutionService, private toastr: ToastrService,
    private sales: SalesService, private dataDefintiion: DataDefinitionService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.dataDefintiion.getStateList().subscribe((state) => {
      this.states = state['data'];
    })
  }

  onKey(event: any) {
    // this.drivingLicence.licence = event.target.value;
    let mobile = this.fetchedUserDetails.Mobile;
    if (event.key === "Enter" || event.key === "10") {
      this.resolution.getCustomerMobileDetails(mobile).subscribe((res) => {
        if (res['data'] != null) {
          this.fetchedUserDetails = res['data'];
          this.sales.getCityList(this.fetchedUserDetails.State).subscribe((cities) => {
            this.cities = cities['data'];
          })
          this.toastr.success(res['message']);
        } else {
          this.toastr.error(res['output'].payload.message);
        }
      });
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  onKeyUp(event: any) {
    // this.drivingLicence.licence = event.target.value;
    let email = this.fetchedUserDetails.Email;
    if (event.key === "Enter") {
      this.resolution.getCustomerEmailDetails(email).subscribe((res) => {
        if (res['data'] != null) {
          this.fetchedUserDetails = res['data'];
          this.sales.getCityList(this.fetchedUserDetails.State).subscribe((cities) => {
            this.cities = cities['data'];
          })
          this.toastr.success(res['message']);
        } else {
          this.toastr.error(res['output'].payload.message);
        }
      });
    }
  }

  onUpdate() {
    this.sales.createCustomerDetail(this.fetchedUserDetails).subscribe((update) => {
      console.log('update', update);
      if (update['data'] != null) {
        this.toastr.success(update['message']);
        this.router.navigate(['/CRM/Resolution/resolution_Panel']);
      } else {
        this.toastr.error(update['output'].payload.message);
      }
    })
  }

  onStateSelection() {
    this.sales.getCityList(this.fetchedUserDetails.State).subscribe((cities) => {
      console.log('cities', cities);
      this.cities = cities['data'];
    })
  }

}
