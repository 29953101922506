import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SharedService {

  resolutionData: any;
  fraudData: any;
  statusdata: any;
  customerData: any;
  ticketDetail: any;
  users: any;
  public country: any = '';
  public Country: any = '';

  constructor() {
    this.users = JSON.parse(localStorage.getItem('currentUser'));
    if (this.users.Country != null) {
      this.country = this.users.Country[0]
      this.updateCountryCode(this.country);
    }
  }

  private countryCode = new BehaviorSubject(this.country);
  currentCountryCode = this.countryCode.asObservable();

  updateCountryCode(message: string) {
    this.countryCode.next(message)
  }

  setResolutionData(val) {
    this.resolutionData = val;
  }

  fetchResolutionData() {
    return this.resolutionData;
  }

  clearResolutionData() {
    this.resolutionData = {};
    return this.resolutionData;
  }

  setFraudData(val) {
    this.fraudData = val;
  }

  setCountryCode(val) {
    this.Country = val;
  }

  fetchCountryCode() {
    return this.Country;
  }

  fetchFraudData() {
    return this.fraudData;
  }

  clearfraudData() {
    this.fraudData = {};
    return this.fraudData;
  }

  setCustomerDetail(val) {
    this.customerData = val;
  }

  fetchCustomerDetail() {
    return this.customerData;
  }

  clearCustomerDetails() {
    this.customerData = {};
    return this.customerData;
  }

  setEditTicketDetail(val) {
    this.ticketDetail = val;
  }

  fetcheditTicketDetail() {
    return this.ticketDetail;
  }
}
