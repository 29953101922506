import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from '../../services/Users/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  users;
  agentCountries;
  country;
  currentRoute;
  navigationSubscription;

  constructor(private router: Router, private _userSer: UserService, private sharedService: SharedService,
    public navCtrl: NgxNavigationWithDataComponent) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.currentRoute = e.url;
      }
    });
  }

  ngOnInit() {
    this.users = JSON.parse(localStorage.getItem('currentUser'));
    if (this.users.Country != null) {
      this.country = this.users.Country[0]
      console.log(this.users.Country)
    }
  }

  onchange() {
    console.log(this.country)
    console.log(this.users)
    this.sharedService.updateCountryCode(this.country)
    this.navCtrl.navigate(this.currentRoute);
  }

  onLogout() {
    this._userSer.logout().subscribe(res => {
      console.log(res, 'logout');
      this.users = '';
      localStorage.clear();
      this.router.navigate(['/login']);
    });
  }
}
