import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layouts-auth',
  templateUrl: './auth.html'
})

export class LayoutsAuthComponent implements OnInit {

  public mylinks: Array<any> = [];
  currentUser;

  constructor(private router: Router) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // if (this.currentUser) {
    //   this.router.navigate(['/CRM/preSalePanel']);
    // }
  }

  ngOnInit() {
    let ie = this.detectIE();
    if (!ie) {
      window.dispatchEvent(new Event('resize'));
    } else {
      // solution for IE from @hakonamatata
      let event = document.createEvent('Event');
      event.initEvent('resize', false, true);
      window.dispatchEvent(event);
    }

    // this.mylinks = [
    //       {
    //           'title': 'Dashboard',
    //           'icon': 'dashboard',
    //           'canAccess': [],
    //           'link': ['/home']
    //       },
    //       { 
    //            'title': 'CRM',
    //            'icon': 'laptop',
    //            'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //            'sublinks': [
    //             {
    //               'title': 'Sales Panel',
    //               'icon': 'dashboard',
    //               'link': ['/preSalePanel'],
    //               'canAccess': ['SuperAdmin']
    //             },
    //             {
    //               'title': 'Resolution Panel',
    //               'icon': 'dashboard',
    //               'link': ['/resolutionPanel'],
    //               'canAccess': ['Admin']
    //             },
    //             {
    //               'title': 'Ticket List Panel',
    //               'icon': 'dashboard',
    //               'link': ['/ticketListPanel'],
    //               'canAccess': ['agent']
    //             },
    //             {
    //               'title': 'Order Status',
    //               'icon': 'dashboard',
    //               'link': ['/orderStatus'],
    //               'canAccess': ['Admin']
    //             },
    //            ]

    //       },

    //       {
    //         'title': 'FAQ',
    //         'icon': 'question-circle',
    //         'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //         'sublinks': [
    //           {
    //             'title': 'FAQ Sales',
    //             'link': ['/faq/faq-sales'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'FAQ Resolution',
    //             'link': ['/faq/faq-resolution'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },

    //         ]
    //       },

    //       {
    //         'title': 'USER',
    //         'icon': 'users',
    //         'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //         'sublinks': [
    //           {
    //             'title': 'Dashboard',
    //             'link': ['/dashboard'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'User Role Definition',
    //             'link': ['/user-role-definition'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'User Definition',
    //             'link': ['/user-definition'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           }
    //         ] 
    //       },

    //       {
    //         'title': 'DATA IMPORT / EXPORT',
    //         'icon': 'upload',
    //         'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //         'sublinks': [
    //           {
    //             'title': 'Bank Data',
    //             'link': ['/bank-data'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Service Provider Data',
    //             'link': ['/service-provider-data'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Embassy Data',
    //             'link': ['/embassy-data'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'FAQ Data',
    //             'link': ['/faq-data'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Resolution Script Data',
    //             'link': ['/resolution-script-data'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Category Data',
    //             'link': ['/category-data'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Bank User Data',
    //             'link': ['/bank-user-data'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Product Data',
    //             'link': ['/product-data'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'City Master Data',
    //             'link': ['/city-master-data'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Sale Bulk User Data',
    //             'link': ['/sale-bulk-user-data'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'RTO Data',
    //             'link': ['/rto-data'],
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           }
    //         ] 
    //       },

    //       {
    //         'title': 'DATA DEFINITION',
    //         'icon': 'database',
    //         'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //         'sublinks': [
    //           {
    //             'title': 'Create/Edit Bank Data',
    //             'link': '/definition-bank-data',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Create/Edit SP Data',
    //             'link': '/definition-sp-data',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Create/Edit Embassy Data',
    //             'link': '/definition-embassy-data',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Create/Edit Script Data',
    //             'link': '/definition-script-data',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Create/Edit FAQ Data',
    //             'link': '/definition-faq-data',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Create/Edit Category Data',
    //             'link': '/definition-category-data',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Create/Edit Bank User Data',
    //             'link': '/definition-bank-user',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Search/Edit Customer Data',
    //             'link': '/definition-customer-data',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Create/Edit City Master Data',
    //             'link': '/definition-city-master',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Create/Edit Product Data',
    //             'link': '/definition-product-data',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Create/Edit RTO Data',
    //             'link': '/definition-rto-data',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },

    //           {
    //             'title': 'Create/Edit Social Media Data',
    //             'link': '/definition-socialMedia-data',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Create/Edit Mail Data',
    //             'link': '/definition-emailId-data',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Create/Edit Wallet Data',
    //             'link': '/definition-wallet-data',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //           {
    //             'title': 'Reset Customer Password',
    //             'link': '/definition-customer-data/resetPassword',
    //             'canAccess': ['SuperAdmin', 'Admin', 'agent'],
    //           },
    //         ] 
    //        }
    //     ]
    //   }
  }

  protected detectIE(): any {
    let ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …
    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
    // IE 12 / Spartan
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
    // Edge (IE 12+)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko)
    // Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    let msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    let trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      let rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    let edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }
}
