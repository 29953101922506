import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
// import { _throw } from 'rxjs/observable/throw'
import { catchError } from 'rxjs/operators';
import { UserService } from '../services/Users/user.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private userService: UserService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log(err, 'aadderr')
            if (err.statusCode === 401) {
                // auto logout if 401 response returned from api
                this.userService.logout();
                localStorage.clear();
                this.router.navigate(['/login']);
            }
            const error = err.error || err.statusText;
            console.log(error, 'aaddad')
            return throwError(error);
        }))
    }
}
