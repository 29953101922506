import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as $ from 'jquery';
import { UserService } from 'src/app/services/Users/user.service';
// declare var $:any;

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  currentUrl: any;
  currentUser;
  roles: any;
  @ViewChild('crm') crm!: ElementRef;

  @Input() links: Array<any> = [];
  users: string;
  showbackGround = false;
  showbackGroundCrm = true;
  showbackGroundSales = false;
  showbackGroundUsers = false;
  showbackGroundImport = false;
  showbackGroundData = false;
  showbackGroundEmail = false;
  path: any;

  constructor(public router: Router, private _userSer: UserService) {
    // getting the current url
    this.router.events.subscribe((evt) => {
      this.currentUrl = evt['url'];
    })
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    console.log(this.currentUser, 'sideabr')
    $(document).ready(() => {
      const trees: any = $('[data-widget="tree"]');
      // trees.tree();
    });
  }

  ngOnInit() {
    // $(document).ready(() => {
    //   const trees: any = $('[data-widget="tree"]');
    //   // trees.tree();
    // });
  }


  toggleBackground(e){
    this.showbackGroundCrm = false
    this.showbackGroundSales = false
    this.showbackGroundUsers = false
    this.showbackGroundImport = false
    this.showbackGroundData = false
    this.showbackGroundEmail = false
   switch (e.currentTarget.id){
      case 'crm':
        this.showbackGroundCrm = !this.showbackGroundCrm
        break
      case 'sales':
        this.showbackGroundSales = !this.showbackGroundSales
        break
      case 'users':
        this.showbackGroundUsers = !this.showbackGroundUsers
        break
      case 'import':
        this.showbackGroundImport = !this.showbackGroundImport
        break
      case 'data':
        this.showbackGroundData = !this.showbackGroundData
        break
      case 'email':
        this.showbackGroundEmail = !this.showbackGroundEmail
        break
    }

     
       
    // console.log(this.crm)
    // this.crm.nativeElement.style.backgroundColor = '#fff';
    // this.crm.nativeElement.style.color = '#000';
// console.log(e)

  }
  
  onLogout() {
    this._userSer.logout().subscribe(res => {
      console.log(res, 'logout');
      this.users = '';
      localStorage.clear();
      this.router.navigate(['/login']);
    });
  }
}
