import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/Users/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  userInfo: any;
  returnUrl: string;
  username: any;
  password: any;
  currentUser: any;

  constructor(private _userServ: UserService, private route: ActivatedRoute, private router: Router,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login(info) {
    if (info.valid) {
      this._userServ.getLoginData(info.value).subscribe(res => {
        this.userInfo = res;
        if (this.userInfo['data'] != null) {
          if (this.userInfo['data'].UserType == 'Agent') {
            localStorage.setItem('currentUser', JSON.stringify(this.userInfo.data));
            localStorage.setItem('AgentName', this.userInfo['data'].username);
            localStorage.setItem('AgentId', this.userInfo['data'].id);
            localStorage.setItem('CallCenterName', this.userInfo['data'].CallCenterName);
            this.toastr.success(this.userInfo['message']);

            this.currentUser = this.userInfo.data;
            if (this.currentUser.Permissions.Resolution) {
              this.router.navigate(['/CRM/Resolution/resolution_Panel']);
            } else {
              this.router.navigate(['/CRM/preSalePanel']);
            }
          } else if (this.userInfo['data'].UserType != 'Agent') {
            this.toastr.success(this.userInfo['message']);
            // this.router.navigate(['/otp-auth'], { queryParams: { key: this.userInfo['data'].key } });
            const navigationExtras: NavigationExtras = {
              state: {
                key: this.userInfo['data'].key,
                username: this.userInfo['data'].username,
                password: info.value.password
              }
            }
            this.router.navigate(['/otp-auth'], navigationExtras);
          }
        } else {
          this.toastr.error(this.userInfo['output'].payload.message);
        }
      });
    }
  }
}
