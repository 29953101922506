import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  serverurl = environment.api_base_url;
  // private token: string;

  constructor(private http: HttpClient) {
    // let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.token = currentUser.token;
  }

  //   Bank Data
  //  getBankDataDownload(a) {
  //   return this.http.get(this.serverurl + 'api/bank/downloadList',{
  //     responseType: 'blob',
  //     params: {
  //      IsActive:a
  //     }})
  //   }

  //   getBankTemplateDownload() {
  //     return this.http.get(this.serverurl + 'api/bank/downloadTemplate',{
  //       responseType: 'blob',
  //      })
  //     }

  // public uploadFile(fileToUpload: File) {
  //   //const _formData = new FormData();
  //   //_formData.append('file', fileToUpload, fileToUpload.name);
  //   return<any>post(UrlFileUpload, _formData);
  // }

  uploadCountryList(fileToUpload: File) {
    return this.http.post(this.serverurl + 'api/country/uploadCountryList', fileToUpload, {
      responseType: 'json'
    })
  }

  uploadBankList(fileToUpload: File) {
    // let _formData = new FormData();
    // //_formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.serverurl + 'api/bank/uploadBankList', fileToUpload, {
      responseType: 'json'
    })
  }

  uploadSpList(fileToUpload: File): Observable<any> {
    // //const _formData = new FormData();
    // //_formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.serverurl + 'api/sp/uploadSPList', fileToUpload, {
      responseType: 'json'
    })
  }

  uploadEmbassyData(fileToUpload: File): Observable<any> {
    // //const _formData = new FormData();
    // //_formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.serverurl + 'api/embassy/uploadEmbassyList', fileToUpload, {
      responseType: 'json'
    })
  }

  uploadResolutionScriptData(fileToUpload: File) {
    // //const _formData = new FormData();
    // //_formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.serverurl + 'api/script/uploadScriptList', fileToUpload, {
      responseType: 'json'
    })
  }

  uploadFaqData(fileToUpload: File): Observable<any> {
    // //const _formData = new FormData();
    // //_formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.serverurl + 'api/faq/uploadFAQList', fileToUpload, {
      responseType: 'json'
    })
  }

  uploadCategoryData(fileToUpload: File) {
    // //const _formData = new FormData();
    // //_formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.serverurl + 'api/category/uploadCategoryList', fileToUpload, {
      responseType: 'json'
    })
  }

  uploadBankUserData(fileToUpload: File) {
    // //const _formData = new FormData();
    // //_formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.serverurl + 'api/bank/customer/uploadBankCustomerList', fileToUpload, {
      responseType: 'json'
    })
  }

  uploadProductData(fileToUpload: File) {
    // //const _formData = new FormData();
    // //_formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.serverurl + 'api/product/uploadProductList', fileToUpload, {
      responseType: 'json'
    })
  }

  uploadSalesBulkUserData(fileToUpload: File): Observable<any> {
    // //const _formData = new FormData();
    // //_formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.serverurl + 'api/customer/uploadCustomerList', fileToUpload, {
      responseType: 'json'
    })
  }

  uploadRtoData(fileToUpload: File) {
    //const _formData = new FormData();
    //_formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.serverurl + 'api/rto/uploadRTOList', fileToUpload, {
      responseType: 'json'
    })
  }

  uploadCityMasterData(fileToUpload: File): Observable<any> {
    //const _formData = new FormData();
    //_formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.serverurl + 'api/master/city/uploadCityMasterList', fileToUpload, {
      responseType: 'json'
    })
  }

  uploadFlowChart(fileToUpload: File, filename: String) {
    return this.http.post(this.serverurl + 'api/category/uploadFlowChart?filename=' + filename, fileToUpload, {
      // headers: new HttpHeaders().set('authorization', this.token).set('Content-Type', 'application/octet-stream'),
      responseType: 'json'
    })
  }


  uploadProductImage(fileToUpload: File, filename: String) {
    return this.http.post(this.serverurl + 'api/product/uploadProductImage?filename=' + filename, fileToUpload, {
      // headers: new HttpHeaders().set('authorization', this.token).set('Content-Type', 'application/octet-stream'),
      responseType: 'json'
    })
  }

  //  uploadBankList(fileToUpload: File) {
  //   return this.http.post(this.serverurl + 'api/bank/uploadBankList',fileToUpload)
  //   }

}
