import { FileSelectDirective, FileDropDirective, FileUploadModule } from 'ng2-file-upload';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    //FileSelectDirective,
    // FileDropDirective
  ],
  imports: [
    FileUploadModule
  ],
  exports: [
    FileSelectDirective,
    FileDropDirective
  ]
})

export class SharedModule { }
