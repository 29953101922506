// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  silent: true,
  //api_base_url: 'http://localhost:4000/',
  //api_base_url_new: 'http://localhost:4000/',
  // api_base_url: 'https://crm.cyberior.com:8081/',
  // api_base_url_new: 'https://crm.cyberior.com:8081/',
  // api_base_url: 'https://uatcrm.cyberior.com:8082/',
  // api_base_url_new: 'https://uatcrm.cyberior.com:8082/',
  api_base_url: 'https://crmapi.cyberior.com/',
  api_base_url_new: 'https://crmapi.cyberior.com/',
  frontend_api_base_url: '',
  set_timeout_sec: 900000
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
