import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  serverurl = environment.api_base_url;

  constructor(private http: HttpClient) { }

  getTicketList(a, b, c): Observable<any> {
    return this.http.get(this.serverurl + 'api/ticket/ticketList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getBasedOnDocId(docId): Observable<any> {
    console.log(docId, 'docId')
    return this.http.get(this.serverurl + 'api/ticket/crmSideDocId', {
      responseType: 'text',
      observe: 'response',
      params: { DocumentId: docId }
    })
  }

  updateTicketDetails(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/ticket/updateTicket', postData);
  }

  getTicketSearchList(a, b, c, d): Observable<any> {
    return this.http.get(this.serverurl + 'api/ticket/ticketList', { 
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
     })
  }

  getTicketDetail(a, b): Observable<any> {
    return this.http.get(this.serverurl + 'api/ticket/getTicketDetails', { params: { TicketId: a, Category: b } })
  }

  getTicketEditDetail(a): Observable<any> {
    return this.http.get(this.serverurl + 'api/ticket/getTicketInformation', { params: { TicketId: a, } })
  }

  getAgentList(): Observable<any> {
    return this.http.get(this.serverurl + 'api/users/agentList');
  }

  getCustomerDetails(): Observable<any> {
    return this.http.get(this.serverurl + 'api/customer/getCustomerUsingDetails');
  }

  getEmailHistory(e): Observable<any> {
    return this.http.get(this.serverurl + 'api/email/list', { params: { Email: e } })
  }

  getEmailHistoryList(e): Observable<any> {
    return this.http.get(this.serverurl + 'api/email/emailList', { params: { Email: e } })
  }
}
