import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // private currentUserSubject: BehaviorSubject<UserType>;
  // public currentUser: Observable<UserType>;

  serverurl = environment.api_base_url;

  constructor(private http: HttpClient) {
    // this.currentUserSubject = new BehaviorSubject<UserType>(JSON.parse(localStorage.getItem('currentUser')));
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  // public get currentUserValue(): UserType {
  //   return this.currentUserSubject.value;
  // }

  logout() {
    return this.http.get(this.serverurl + 'api/logout');
  }

  getLoginData(info): Observable<any> {
    return this.http.post(this.serverurl + 'api/login', info);
  }

  verifyOTP(info): Observable<any> {
    return this.http.post(this.serverurl + 'api/otpverification', info);
  }

  otpCredit(info): Observable<any> {
    return this.http.post(this.serverurl + 'api/otpcredit', info);
  }

  getAll(): Observable<any> {
    return this.http.get(this.serverurl + 'api/users/userList');
  }

  // getUserInfo(usertoken: string) {
  //     let postData = {};
  //     return this.http.post(this.serverurl + 'api/login?ticket=' + usertoken, postData)
  //         .map((response: Response) => response.json())
  //         .catch(this._errorHandler);
  // }

  // getUserInformation(usertoken: string, userId: any) {
  //     let form = new FormData();
  //     form.append('userName', userId);
  //     return this.http.post(this.serverurl_new + 'api/login?ticket=' + usertoken, form)
  //         .map((response: Response) => response.json())
  //         .catch(this._errorHandler);
  // }

  //   logout() {
  //     // remove user from local storage to log user out
  //     window.localStorage.removeItem('currentUser');
  //     this.currentUserSubject.next(null);
  // }

  // canCurrentUser(permissionSting: string) {
  //   let currentUserData;
  //   let permission;
  //   this.currentUser.subscribe((user) => currentUserData = user);
  //   permission = (currentUserData.permissions || []);
  //   return (permission.indexOf(permissionSting) > -1);
  // }

  // _errorHandler(error: Response) {
  //   console.error(error);
  //   return Observable.throw(error || 'server error');
  // }
}
