import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataDefinitionService {

  serverurl = environment.api_base_url;
  CountryList = [
    { name: 'India', code: 'IN' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Hong Kong S.A.R.', code: 'HK' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Vietnam', code: 'VN' }
  ]

  constructor(private http: HttpClient) { }

  // loadCountries() {
  //   return this.CountryList;
  // }

  loadMultiSiteCountries() {
    return this.http.get(this.serverurl + 'api/country/multisiteList')
  }

  loadCountryDropdown() {
    return this.http.get(this.serverurl + 'api/country/list')
  }

  addCountry(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/country/create', postData)
  }

  loadCountrys(a, b, c): Observable<any> {
    return this.http.get(this.serverurl + 'api/country/countryList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  searchCountryList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/country/countryList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  editCountry(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/country/edit', postData)
  }

  StatesBasedOnCntry(info) {
    return this.http.get(this.serverurl + 'api/master/countryBasedStates', { params: { CountryCode: info } });
  }

  getBankList(a, b, c) {
    return this.http.get(this.serverurl + 'api/bank/bankList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchBankList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/bank/bankList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getSpList(a, b, c) {
    return this.http.get(this.serverurl + 'api/sp/spList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchSpList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/sp/spList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getEmbassyList(a, b, c) {
    return this.http.get(this.serverurl + 'api/embassy/embassyList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchEmbassyList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/embassy/embassyList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getFaqList(a, b, c) {
    return this.http.get(this.serverurl + 'api/faq/faqList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getFAQSubCategoryList(a) {
    return this.http.get(this.serverurl + 'api/faq/getFAQSubCategoryList', {
      params: {
        Category: a
      }
    })
  }

  getSearchFaqList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/faq/faqList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getScriptList(a, b, c) {
    return this.http.get(this.serverurl + 'api/script/scriptList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchScriptList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/script/scriptList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getCategoryList(a, b, c) {
    return this.http.get(this.serverurl + 'api/category/categoryList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchCategoryList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/category/categoryList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getBankUserList(a, b, c) {
    return this.http.get(this.serverurl + 'api/bank/customer/getBankcustomerList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchBankUserList(a, b, c, d) {
    console.log('------------------>', a)
    return this.http.get(this.serverurl + 'api/bank/customer/getBankcustomerList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getProductListForDefinition(a, b, c) {
    return this.http.get(this.serverurl + 'api/product/productListForDefinition', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchProductListForDefinition(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/product/productListForDefinition', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getRtoList(a, b, c) {
    return this.http.get(this.serverurl + 'api/rto/rtoList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchRtoList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/rto/rtoList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getStateList() {
    return this.http.get(this.serverurl + 'api/master/states/getStateList');
  }

  getSearchStateMasterList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/master/states/getStateList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getCityMasterList(a, b, c) {
    return this.http.get(this.serverurl + 'api/master/city/getCityList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchCityMasterList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/master/city/getCityList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getCustomerList(a, b, c) {
    return this.http.get(this.serverurl + 'api/customer/customerListForDefinition', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchCustomerList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/customer/customerListForDefinition', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getSocialMediaDropdown() {
    return this.http.get(this.serverurl + 'api/socialMedias/list')
  }

  getSocialMediaList(a, b, c) {
    return this.http.get(this.serverurl + 'api/socialMedias/socialMediaList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchSocialMediaList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/socialMedias/socialMediaList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getEmailIdListDropdown() {
    return this.http.get(this.serverurl + 'api/mailers/list')
  }

  getEmailIdList(a, b, c) {
    return this.http.get(this.serverurl + 'api/mailers/MailersList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchEmailIdList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/mailers/MailersList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  getWalletDropdown() {
    return this.http.get(this.serverurl + 'api/walletpartners/list')
  }

  getWalletList(a, b, c) {
    return this.http.get(this.serverurl + 'api/walletpartners/walletPartnersList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchWalletList(a, b, c, d) {
    return this.http.get(this.serverurl + 'api/walletpartners/walletPartnersList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  createBank(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/bank/create', postData)
  }

  editBank(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/bank/edit', postData)
  }

  createServiceProvider(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/sp/create', postData)
  }

  editServiceProvider(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/sp/edit', postData)
  }

  createembassy(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/embassy/create', postData)
  }

  editEmabassy(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/embassy/edit', postData)
  }

  createFAQ(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/faq/create', postData)
  }

  editFAQ(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/faq/edit', postData)
  }

  createScript(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/script/create', postData)
  }

  editScript(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/script/edit', postData)
  }

  createCategory(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/category/create', postData)
  }

  editCategory(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/category/edit', postData)
  }

  createBankUser(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/bank/customer/create', postData)
  }

  editBankUser(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/bank/customer/edit', postData)
  }

  createRto(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/rto/create', postData)
  }

  editRto(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/rto/edit', postData)
  }

  createCityMaster(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/master/city/create', postData)
  }

  editCitymaster(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/master/city/edit', postData)
  }

  createProduct(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/product/create', postData)
  }

  editProduct(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/product/edit', postData)
  }

  editCustomer(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/customer/edit', postData)
  }

  createSocialMedia(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/socialMedias/create', postData)
  }

  editSocialMedia(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/socialMedias/edit', postData)
  }

  createMailerName(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/mailers/create', postData)
  }

  editMailerName(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/mailers/edit', postData)
  }

  createWallet(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/walletpartners/create', postData)
  }

  editWallet(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/walletpartners/edit', postData)
  }

  CallCenterList(a, b, c): Observable<any> {
    console.log(a, b, c, 'a,b,c')
    return this.http.get(this.serverurl + 'api/callcenters/getcallsList', {
      params: {
        pageNo: a,
        limit: b,
        sort: c
      }
    })
  }

  getSearchCallsList(a, b, c, d): Observable<any> {
    return this.http.get(this.serverurl + 'api/callcenters/getcallsList', {
      params: {
        search: a,
        pageNo: b,
        limit: c,
        sort: d
      }
    })
  }

  createCalls(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/callcenters/create', postData)
  }

  editCalls(postData): Observable<any> {
    return this.http.post(this.serverurl + 'api/callcenters/edit', postData)
  }

  getCallsDropdown() {
    return this.http.get(this.serverurl + 'api/callcenters/listofCalls')
  }
}
